<template>
	<div id="course">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学年学期</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class="left">
						<div class="add u-f-justify el-icon-plus" @click="addxuenian = true"></div>
						<div class="time_list">
							<div
								class="time_item u-f-item u-f-jsb"
								:class="xuenianIndex == index ? 'active' : ''"
								v-for="(item, index) in xuenianList"
								:key="index"
								@mouseover="item.isShow = true"
								@mouseleave="item.isShow = false"
								@click="tabXueqi(index)"
							>
								<div class="u-f-item">{{ item.name }}{{ item.is_default == 2 ? '（正在使用）' : '' }}</div>
								<div class="u-f-item btn" v-if="item.isShow">
									<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editxuenianShow(item)"></i>
									<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delxuenian(item.id)"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="u-f-item">
							<div style="padding: 0 20px 0 30px;" class="font_16 u-f-item" v-if="xuenianList.length">
								<div>{{ xuenianList[xuenianIndex].name }}{{xuenianList[xuenianIndex].is_default == 2 ? '（正在使用）' : '' }}</div>
							</div>
							<el-button size="mini" @click="addxueqi = true">添加学期</el-button>
						</div>
						<div class="xueqiList" v-if="xueqiList.length">
							<div class="xueqi-item" v-for="(item, index) in xueqiList" :key="index">
								<div class="cont">
									<div class="u-f-item u-f-jsb" style="margin-bottom: 30px;">
										<div>
											<i class="el-icon-s-flag"></i>
											{{ item.name }}
										</div>
										<el-switch @change="setDefault(item.id)" v-model="item.is_default" active-value="2" inactive-value="1"></el-switch>
									</div>

									<div style="color: #ccc;margin-bottom: 10px;">学期呈现日期：{{ item.semester_start }}~{{ item.semester_end }}</div>
									<div style="color: #ccc;">周呈现日期：{{ item.week_start }}~{{ item.week_end }}</div>
								</div>
								<div class="u-f-item meun">
									<div class="u-f1 u-f-justify meun-item" @click="editShow(item)">
										<i class="el-icon-edit"></i>
										编辑
									</div>
									<div class="u-f1 u-f-justify meun-item" @click="delCampus(item.id)">
										<i class="el-icon-delete"></i>
										删除
									</div>
								</div>
							</div>
						</div>
						<div class="u-f-justify u-f-column" v-else style="height: 70vh;">
							<img src="../../../assets/image/nothing.png" style="width: 120px;" />
							<div style="color: #A4A4A4;">暂无数据</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 添加学年 -->
			<el-dialog title="添加学年" :visible.sync="addxuenian" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学年名称
						</div>
						<el-input v-model="xuenian_name" placeholder="请输入学年名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addxuenian = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitAddXuenian">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑学年 -->
			<el-dialog title="编辑学年" :visible.sync="editxuenian" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学年名称
						</div>
						<el-input v-model="edit_xuenian_name" placeholder="请输入名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editxuenian = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitEditXuenian">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 添加学期 -->
			<el-dialog title="添加学期" :visible.sync="addxueqi" width="540px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学年
						</div>
						<el-input v-model="xuenian_name" placeholder="请输入名称" style="width: 70%;" disabled></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学期名称
						</div>
						<el-input v-model="xueqi_name" placeholder="请输入学期名称" style="width: 70%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							保留当前排课表
						</div>
						<div style="width: 70%;">
							<el-radio-group v-model="is_save_course">
								<el-radio :label="1">否</el-radio>
								<el-radio :label="2">是</el-radio>
							</el-radio-group>
							<el-alert v-if="xuenianList.length" :title="'当前为' + xuenianList[xuenianIndex].name + '学年'" type="warning" show-icon :closable="false" style="margin-top: 15px;"></el-alert>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学期呈现日期
						</div>
						<div class="u-f-item" style="width: 70%;">
							<el-date-picker value-format="yyyy-MM-dd" v-model="semester_start" type="date" placeholder="开始日期" style="width: 155px;"></el-date-picker>
							<span style="margin: 0 10px;">-</span>
							<el-date-picker value-format="yyyy-MM-dd" v-model="semester_end" type="date" placeholder="结束日期" style="width: 155px;"></el-date-picker>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							周呈现日期
						</div>
						<div style="width: 70%;">
							<div class="u-f-item">
								<el-date-picker value-format="yyyy-MM-dd" v-model="week_start" type="date" placeholder="开始日期" style="width: 155px;"></el-date-picker>
								<span style="margin: 0 10px;">-</span>
								<el-date-picker value-format="yyyy-MM-dd" v-model="week_end" type="date" placeholder="结束日期" style="width: 155px;"></el-date-picker>
							</div>
							<el-alert title="周程日期必须在学期日期内" type="warning" show-icon :closable="false" style="margin-top: 15px;"></el-alert>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addxueqi = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitAddXueqi">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 编辑学期 -->
			<el-dialog title="编辑学期" :visible.sync="editxueqi" width="540px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学年
						</div>
						<el-input v-model="xuenian_name" placeholder="请输入名称" style="width: 70%;" disabled></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学期名称
						</div>
						<el-input v-model="xueqi_name" placeholder="请输入学期名称" style="width: 70%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							保留当前排课表
						</div>
						<div style="width: 70%;">
							<el-radio-group v-model="is_save_course">
								<el-radio :label="1" disabled>否</el-radio>
								<el-radio :label="2" disabled>是</el-radio>
							</el-radio-group>
							<el-alert v-if="xuenianList.length" :title="'当前为' + xuenianList[xuenianIndex].name + '学年'" type="warning" show-icon :closable="false" style="margin-top: 15px;"></el-alert>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							学期呈现日期
						</div>
						<div class="u-f-item" style="width: 70%;">
							<el-date-picker value-format="yyyy-MM-dd" v-model="semester_start" type="date" placeholder="开始日期" style="width: 155px;"></el-date-picker>
							<span style="margin: 0 10px;">-</span>
							<el-date-picker value-format="yyyy-MM-dd" v-model="semester_end" type="date" placeholder="结束日期" style="width: 155px;"></el-date-picker>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="width: 120px;text-align: right;">
							<span>*</span>
							周呈现日期
						</div>
						<div style="width: 70%;">
							<div class="u-f-item">
								<el-date-picker value-format="yyyy-MM-dd" v-model="week_start" type="date" placeholder="开始日期" style="width: 155px;"></el-date-picker>
								<span style="margin: 0 10px;">-</span>
								<el-date-picker value-format="yyyy-MM-dd" v-model="week_end" type="date" placeholder="结束日期" style="width: 155px;"></el-date-picker>
							</div>
							<el-alert title="周程日期必须在学期日期内" type="warning" show-icon :closable="false" style="margin-top: 15px;"></el-alert>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editxueqi = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitEditXueqi">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			xuenianList: [],
			addxuenian: false,
			xueqiList: [],
			xuenianId: '',
			xuenian_name: '',
			editxuenian: false,
			edit_xuenian_name: '',
			edit_xuenian_id: '',
			xuenianIndex: 0,
			addxueqi: false,
			xueqi_name: '',
			xuenian_name: '',
			is_save_course: '',
			semester_start:'',
			semester_end:'',
			week_start:'',
			week_end:'',
			editxueqi:false,
			xueqiId:''
		};
	},
	mounted() {
		this.getSemesterList();
	},
	methods: {
		tabXueqi(index) {
			this.xuenianIndex = index;
			this.getXueqiList();
		},
		handleClose(done) {
			done();
			this.info()
		},
		// 获取学年列表
		getSemesterList() {
			this.$api.setting.getSemesterList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						data[i].isShow = false;
						if (data[i].is_default == 2) {
							this.xuenianIndex = i;
						}
					}
					this.xuenianList = data;
					this.getXueqiList();
				}
			});
		},
		getXueqiList() {
			this.$api.setting
				.getXueQiList({
					year_id: this.xuenianList[this.xuenianIndex].id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.xueqiList = res.data.data;
						this.xuenian_name = this.xuenianList[this.xuenianIndex].name;
						if (this.xuenianList[this.xuenianIndex].is_default == 2) {
							this.xuenian_name = this.xuenian_name + '(正在使用)';
						}
						console.log(this.xuenian_name);
					}
				});
		},
		// 添加学年
		submitAddXuenian() {
			if (this.xuenian_name == '' || !this.xuenian_name) {
				return this.$message.error('请输入名称');
			}
			this.$api.setting
				.addSemester({
					name: this.xuenian_name
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.getSemesterList();
						this.addxuenian = false;
						this.xuenian_name = '';
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑学年
		editxuenianShow(item) {
			this.edit_xuenian_id = item.id;
			this.edit_xuenian_name = item.name;
			this.editxuenian = true;
		},
		submitEditXuenian() {
			this.$api.setting
				.editSemester({
					id: this.edit_xuenian_id,
					name: this.edit_xuenian_name
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.getSemesterList();
						this.editxuenian = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除学年
		delxuenian(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delSemester({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getSemesterList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		// 添加学期
		submitAddXueqi() {
			if(this.xueqi_name==''||!this.xueqi_name){
				return this.$message.error('请输入名称')
			}
			if(this.is_save_course==''||!this.is_save_course){
				return this.$message.error('请选择是否保留当前排课表')
			}
			if(this.semester_start==''||!this.semester_start){
				return this.$message.error('学期呈现开始日期')
			}
			if(this.semester_end==''||!this.semester_end){
				return this.$message.error('学期呈现结束日期')
			}
			if(this.week_start==''||!this.week_start){
				return this.$message.error('周程呈现开始日期')
			}
			if(this.week_end==''||!this.week_end){
				return this.$message.error('周程呈现结束日期')
			}
			let data = {
				name:this.xueqi_name,
				pid:this.xuenianList[this.xuenianIndex].id,
				is_save_course:this.is_save_course,
				semester_start:this.semester_start,
				semester_end:this.semester_end,
				week_start:this.week_start,
				week_end:this.week_end
			}
			this.$api.setting.addXueQi(data).then(res=>{
				if(res.data.code==1){
					this.$message.error('添加成功')
					this.info()
					this.getXueqiList();
					this.addxueqi = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		editShow(item){
			console.log(item)
			this.xueqiId = item.id;
			this.xueqi_name = item.name;
			this.is_save_course = Number(item.is_save_course);
			this.semester_start = item.semester_start||'';
			this.semester_end = item.semester_end||'';
			this.week_start = item.week_start||'';
			this.week_end = item.week_end||'';
			this.editxueqi = true;
		},
		submitEditXueqi(){
			if(this.xueqi_name==''||!this.xueqi_name){
				return this.$message.error('请输入名称')
			}
			if(this.is_save_course==''||!this.is_save_course){
				return this.$message.error('请选择是否保留当前排课表')
			}
			if(this.semester_start==''||!this.semester_start){
				return this.$message.error('学期呈现开始日期')
			}
			if(this.semester_end==''||!this.semester_end){
				return this.$message.error('学期呈现结束日期')
			}
			if(this.week_start==''||!this.week_start){
				return this.$message.error('周程呈现开始日期')
			}
			if(this.week_end==''||!this.week_end){
				return this.$message.error('周程呈现结束日期')
			}
			let data = {
				id:this.xueqiId,
				name:this.xueqi_name,
				pid:this.xuenianList[this.xuenianIndex].id,
				is_save_course:this.is_save_course,
				semester_start:this.semester_start,
				semester_end:this.semester_end,
				week_start:this.week_start,
				week_end:this.week_end
			}
			this.$api.setting.editXueQi(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.info()
					this.getXueqiList();
					this.editxueqi = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		info(){
			this.xueqi_name = '';
			this.is_save_course = '';
			this.semester_start = '';
			this.semester_end = '';
			this.week_start = '';
			this.week_end = '';
		},
		delCampus(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delXueQi({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getXueqiList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		setDefault(id){
			this.$api.setting.setDefault({id:id}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.getSemesterList();
				} else {
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss">
#course {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.time_list {
				padding: 25px;
				.time_item {
					margin-top: 15px;
					padding: 0 12px 0 25px;
					height: 40px;
					line-height: 40px;
					background-color: #f8f8f8;
					font-size: 0.625vw;
					.btn {
						i {
							background-color: #ffffff;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.time_item:hover {
					span {
						color: #007aff;
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
			}
		}
		.right {
			width: 100%;
			height: 75vh;
			padding: 25px 50px;
			.xueqiList {
				padding: 30px;
				display: flex;
				flex-wrap: wrap;
				.xueqi-item {
					border: 1px solid #eeeeee;
					background-color: #ffffff;
					width: 30%;
					margin-right: 20px;
					margin-bottom: 20px;
					border-radius: 3px;
					.cont {
						height: 10vh;
						padding: 30px;
						.section {
							font-size: 0.625vw;
							background-color: #e4f1ff;
							color: #2d8cf0;
							display: inline-block;
							padding: 3px 10px;
						}
						.el-icon-s-flag {
							color: #2d8cf0;
							margin-right: 3px;
						}
					}
					.meun {
						padding: 10px 0;
						background-color: #fafafa;
						color: #8d9da6;
						div {
							border-right: 1px solid #e8eaec;
							cursor: pointer;
							i {
								margin-right: 3px;
							}
						}
						div:last-child {
							border-right: none;
						}
						.meun-item:hover {
							color: #ed6335;
						}
					}
				}
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
